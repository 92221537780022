import { useState, useEffect } from "react";
import { useGoogleMap } from "@ubilabs/google-maps-react-hooks";
import { useDispatch, useSelector } from "react-redux";
// import { setSelectedCalls, addToselectedCalls } from "./cagriSlice";
import { addToselectedCalls } from "./cagriSlice";
import { MarkerClusterer } from "@googlemaps/markerclusterer";
import foodPin from "../media/food_pin.png";
// import shelterPin from "../media/shelter_pin.png";
// import clothPin from "../media/cloth_pin.png";
import healthPin from "../media/health_pin.png";
import otherPin from "../media/other_pin.png";
import { useNavigate } from "react-router-dom";
// import SuperClusterAlgorithm from "./superClusterAlgorithm";

const MapMarkers = (props) => {
  const dispatch = useDispatch();
  const [markerClustererComponent, setMarkerClusterer] = useState(null);
  const [prevCagrilar, setPrevCagrilar] = useState([]);

  const navigate = useNavigate();
  const cagrilar = useSelector((state) => state.cagri.emergencycalls);
  // focused city
  const focusedCity = useSelector((state) => state.cagri.focusedCity);
  const selectedCagrilar = useSelector((state) => state.cagri.selectedCalls);

  const map = useGoogleMap();
  // const [createdMarkers, setCreatedMarkers] = useState({});

  const [, setMarkers] = useState([]);

  const epochtoTime = (epoch) => {
    const date = new Date(epoch * 1000);
    const currentDate = new Date();
    if (isNaN(date.getTime())) {
      return "Tarih Bulunamadı";
    }

    const timeDiff = currentDate.getTime() - date.getTime();
    const daysDiff = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
    const hoursDiff = Math.floor((timeDiff / (1000 * 60 * 60)) % 24);
    const options = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: false,
    };
    const formattedDate = date.toLocaleString("default", options);
    const formattedDiff = `(${daysDiff} gün ${hoursDiff} saat önce)`;

    return [formattedDate, formattedDiff];
  };

  const handleMarkerClick = (marker) => {
    const alreadySelected = selectedCagrilar.findIndex(
      (selected) => selected.emergency_call_id === marker.emergency_call_id
    );

    if (alreadySelected === -1) {
      dispatch(addToselectedCalls(marker));
      return true;
    }
    return false;
  };

  const markerRenderer = ({ count, position }) =>
    new window.google.maps.Marker({
      label: { text: String(count), color: "white", fontSize: "10px" },
      position,
      // adjust zIndex to be above other markers
      zIndex: Number(window.google.maps.Marker.MAX_ZINDEX) + count,
    });

  // Add markers to the map
  useEffect(() => {
    if (!map) {
      return () => {};
    }
    const markerList = [];

    // if selected city is not null, center map to that city according to its lat and lng
    if (focusedCity) {
      map.setCenter({
        lat: parseFloat(focusedCity.lat),
        lng: parseFloat(focusedCity.lng),
      });
      map.setZoom(8);
    }

    const initialBounds = new window.google.maps.LatLngBounds();

    const emergencyCallMarkers = cagrilar.map((cagri) => {
      const markerOptions = {
        map,
        position: {
          lat: parseFloat(cagri.lat),
          lng: parseFloat(cagri.lng),
        },
        title: cagri.message,
        // clickable: false,
      };

      if (cagri.category === "gida") {
        markerOptions["icon"] = {
          url: foodPin,
          scaledSize: new window.google.maps.Size(40, 64),
        };
      } else if (cagri.category === "diger") {
        markerOptions["icon"] = {
          url: otherPin,
          scaledSize: new window.google.maps.Size(40, 64),
        };
      } else if (cagri.category === "saglik") {
        markerOptions["icon"] = {
          url: healthPin,
          scaledSize: new window.google.maps.Size(40, 64),
        };
      }

      initialBounds.extend({
        lat: parseFloat(cagri.lat),
        lng: parseFloat(cagri.lng),
      });

      const infoWindow = new window.google.maps.InfoWindow({
        content: "",
        disableAutoPan: true,
      });

      // Remove all markers and marker clusters from the map before adding new ones
      if (prevCagrilar.length > 0 && markerClustererComponent) {
        markerClustererComponent.clearMarkers();
        prevCagrilar.forEach((cagri) => {
          const marker = markerList.find((m) => m.getTitle() === cagri.message);
          if (marker) {
            marker.setMap(null);
          }
        });
      }

      setPrevCagrilar(cagrilar);

      const marker = new window.google.maps.Marker(markerOptions);
      markerList.push(marker);
      const cagriDate = epochtoTime(cagri.created_at);

      const cagriStatus = cagri.status;
      let cagriStatusText;

      if (cagriStatus === "0") {
        cagriStatusText = "Yardım Bekleniyor";
      } else if (cagriStatus === "1") {
        cagriStatusText = "Yardım Yolda";
      } else if (cagriStatus === "2") {
        cagriStatusText = "Yardım Edildi";
      } else if (cagriStatus === "3") {
        cagriStatusText = "İptal Edildi";
      }

      marker.addListener("click", () => {
        const submitButton = document.createElement("input");
        submitButton.type = "submit";
        submitButton.value = "Listeye Ekle";
        submitButton.addEventListener("click", () => {
          const isAdded = handleMarkerClick(cagri);
          if (isAdded) {
            const successMessage = document.createElement("p");
            successMessage.textContent =
              "Listeye eklendi! (Aşağı kaydır \u2193)";
            successMessage.style.color = "green";
            successMessage.style.fontWeight = "bold";
            successMessage.style.marginTop = "3px";
            content.appendChild(successMessage);
          }
        });
        submitButton.style.background = "#0077C2";
        submitButton.style.color = "white";
        submitButton.style.border = "none";
        submitButton.style.borderRadius = "5px";
        submitButton.style.padding = "8px 16px";
        submitButton.style.cursor = "pointer";
        const bar = document.createElement("div");
        bar.id = "bar";

        const date = document.createElement("div");
        date.style.position = "absolute";
        date.style.top = "0";
        date.style.left = "0";
        date.style.padding = "5px";
        date.style.backgroundColor = "white";
        date.style.fontSize = "12px";
        date.style.fontWeight = "bold";
        date.textContent = cagriDate[0] + " " + cagriDate[1];
        // add cagriDate1 under the date

        const content = document.createElement("div");
        const statusText = document.createElement("p");
        statusText.textContent = `Durum: ${cagriStatusText}`;
        statusText.style.fontWeight = "bold";
        // add on top of text
        statusText.style.position = "absolute";
        statusText.style.top = "35px";
        statusText.style.left = "0";
        statusText.style.padding = "5px";
        statusText.style.backgroundColor = "white";
        statusText.style.fontSize = "12px";

        // set cagri.message as inner dangerous html
        const p = document.createElement("p");
        p.innerHTML =
          cagri.message.length > 150
            ? cagri.message.substring(0, 150) + "..."
            : cagri.message;

        p.style.marginTop = "50px";

        content.style.minWidth = "200px";
        content.appendChild(statusText);
        content.appendChild(date);
        content.appendChild(p);
        content.appendChild(submitButton);
        content.appendChild(bar);

        const br = document.createElement("br");

        // add button to maps directions https://www.google.com/maps/dir/?api=1&destination=${cagri.lat},${cagri.lng}
        const directionsButton = document.createElement("input");
        directionsButton.type = "submit";
        directionsButton.value = "Yol Tarifi";
        directionsButton.addEventListener("click", () => {
          window.open(
            `https://www.google.com/maps/dir/?api=1&destination=${cagri.lat},${cagri.lng}`,
            "_blank"
          );
        });
        directionsButton.style.background = "white";
        directionsButton.style.color = "#0077C2";
        directionsButton.style.border = "none";
        directionsButton.style.borderRadius = "5px";
        directionsButton.style.cursor = "pointer";
        directionsButton.style.textDecoration = "none";
        content.appendChild(br);
        content.appendChild(directionsButton);

        const linkButton = document.createElement("input");
        linkButton.type = "submit";
        linkButton.value = "Detaylar";
        linkButton.addEventListener("click", () => {
          navigate(`/cagri/${cagri.emergency_call_id}`);
        });
        linkButton.style.background = "white";
        linkButton.style.color = "#0077C2";
        linkButton.style.border = "none";
        linkButton.style.borderRadius = "5px";
        linkButton.style.cursor = "pointer";
        linkButton.style.textDecoration = "none";
        content.appendChild(br);
        content.appendChild(linkButton);

        infoWindow.setContent(content);
        infoWindow.open(map, marker);
      });

      return marker;
    });

    // setCreatedMarkers(emergencyCallMarkers);
    setMarkers(emergencyCallMarkers);

    const markerCluster = new MarkerClusterer({
      map,
      markers: emergencyCallMarkers,
      calculator: markerRenderer,
    });
    setMarkerClusterer(markerCluster);

    // Clean up markers
    return () => {
      emergencyCallMarkers.forEach((marker) => marker.setMap(null));
    };

    // eslint-disable-next-line
  }, [map, cagrilar, focusedCity.city]);

  return <></>;
};

export default MapMarkers;
