import "./CagriListesi.scss";
import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button, Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import Alert from "@mui/material/Alert";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Spinner from "../media/spinner.gif";

const statusList = [
  { id: "0", label: "Yardım Bekleniyor" },
  { id: "1", label: "Yardım Yolda" },
  { id: "2", label: "Yardım Edildi" },
];

const cities = [
  "Kahramanmaraş",
  "Hatay",
  "Gaziantep",
  "Malatya",
  "Diyarbakır",
  "Kilis",
  "Şanlıurfa",
  "Adıyaman",
  "Osmaniye",
  "Adana",
];

const categories = [{ diger: "Diğer" }, { gida: "Gıda" }, { saglik: "Sağlık" }];
const categoryList = { diger: "Diğer", gida: "Gıda", saglik: "Sağlık" };

export default function CagriListesi() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [cityFilter, setCityFilter] = useState("Kahramanmaraş");
  const [showEmptyDataAlert, setShowEmptyDataAlert] = useState(false);
  const [cagrilar, setCagrilar] = useState([]);
  const [categoryFilter, setCategoryFilter] = useState([
    "diger",
    "gida",
    "saglik",
  ]);
  const [statusFilter, setStatusFilter] = useState(["0", "1", "2"]);

  const fetchCagrilar = () => {
    setShowEmptyDataAlert(false);
    setLoading(true);
    let apiUrl = `https://rn3ovzqjig.execute-api.eu-central-1.amazonaws.com/api/emergency/filter?city=${cityFilter}`;
    if (categoryFilter !== "") {
      apiUrl += `&category=${categoryFilter}`;
    }
    if (statusFilter !== "") {
      apiUrl += `&status=${statusFilter}`;
    }

    axios
      .get(apiUrl)
      .then((res) => {
        if (res.data.body.length === 0) {
          setShowEmptyDataAlert(true);
          setCagrilar([]);
        } else {
          setShowEmptyDataAlert(false);
          // save data by created_date desc
          const sortedCagrilar = res.data.body.sort((a, b) => {
            return b.created_at - a.created_at;
          });

          setCagrilar(sortedCagrilar);
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        // console.log(error)
      });
  };

  const epochtoTime = (epoch) => {
    const date = new Date(epoch * 1000);
    const currentDate = new Date();
    if (isNaN(date.getTime())) {
      return "Tarih Bulunamadı";
    }

    const timeDiff = currentDate.getTime() - date.getTime();
    const daysDiff = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
    const hoursDiff = Math.floor((timeDiff / (1000 * 60 * 60)) % 24);
    const options = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: false,
    };
    const formattedDate = date.toLocaleString("default", options);
    // const formattedDiff = `(${daysDiff} gün ${hoursDiff} saat önce)`;

    return formattedDate;
  };

  useEffect(() => {
    if (cityFilter !== "") fetchCagrilar();

    // eslint-disable-next-line
  }, []);

  return (
    <div>
      <h1>Çağrı Listesi</h1>
      <Container>
        <Row>
          <Col xs={12} md={12} xl={6}>
            <Container className="filtre-box city">
              <span>Şehir Seç:</span>
              <Row xs={2} md={3}>
                {cities.map((city, index) => (
                  <Col key={city}>
                    <div style={{ margin: "0.2rem" }}>
                      <input
                        type="radio"
                        name="city"
                        value={city}
                        checked={cityFilter === city}
                        onChange={() => setCityFilter(city)}
                      />
                      <label htmlFor={city} style={{ marginLeft: "0.5rem" }}>
                        {city}
                      </label>
                    </div>
                  </Col>
                ))}
              </Row>
            </Container>
          </Col>
          <Col xs={12} xl={6}>
            <Container className="filtre-box status">
              <span>Durum Seç:</span>

              {statusList.map((status, index) => (
                <Row key={index}>
                  <div style={{ margin: "1rem 0" }}>
                    <input
                      type="checkbox"
                      name="status"
                      value={status.id}
                      checked={statusFilter.includes(status.id)}
                      onChange={() => {
                        if (statusFilter.includes(status.id)) {
                          setStatusFilter(
                            statusFilter.filter((id) => id !== status.id)
                          );
                        } else {
                          setStatusFilter([...statusFilter, status.id]);
                        }
                      }}
                    />
                    <label htmlFor={status.id} style={{ marginLeft: "0.5rem" }}>
                      {status.label}
                    </label>
                  </div>
                </Row>
              ))}
            </Container>
          </Col>
          <Col xs={12} xl={6}>
            <Container className="filtre-box category">
              <span>Kategori Seç:</span>
              {categories.map((category, index) => {
                const [categoryId, categoryName] = Object.entries(category)[0];
                const isChecked = categoryFilter.includes(categoryId);
                return (
                  <Row key={index}>
                    <div style={{ margin: "1rem 0" }}>
                      <input
                        type="checkbox"
                        name="category"
                        value={categoryId}
                        checked={isChecked}
                        onChange={() => {
                          if (isChecked) {
                            setCategoryFilter(
                              categoryFilter.filter((id) => id !== categoryId)
                            );
                          } else {
                            setCategoryFilter([...categoryFilter, categoryId]);
                          }
                        }}
                      />
                      <label
                        htmlFor="category"
                        style={{ marginLeft: "0.5rem" }}
                      >
                        {categoryName}
                      </label>
                    </div>
                  </Row>
                );
              })}
            </Container>
          </Col>
        </Row>
      </Container>
      <Button onClick={fetchCagrilar}>
        Çağrıları Göster{" "}
        {loading && (
          <img
            src={Spinner}
            alt="loading"
            style={{ width: "1.5rem", padding: "0" }}
          />
        )}
      </Button>
      <br />
      {!loading && (
        <p>
          <b>{cagrilar.length}</b> çağrı bulundu.
        </p>
      )}
      {showEmptyDataAlert && (
        <Alert
          style={{ marginBottom: "0.5rem" }}
          severity="warning"
          onClose={() => setShowEmptyDataAlert(false)}
        >
          Seçtiğiniz filtrelerle bir çağrı bulunamadı.
        </Alert>
      )}
      <div className="cagri-listesi">
        {cagrilar.map((cagri, index) => (
          <div key={index} className="cagri-card">
            <div>
              <div className="cagri-details">
                <span className="cagri-date">
                  {epochtoTime(cagri.created_at)}
                </span>
                {/* <br /> */}
                <span className="cagri-status">
                  {
                    statusList.find((status) => status.id === cagri.status)
                      .label
                  }
                </span>
              </div>

              <span className="cagri-category">
                <b>Kategori:</b>
                {
                  categories.find(
                    (category) => Object.keys(category)[0] === cagri.category
                  )[cagri.category]
                }
              </span>
              <br />

              <b>Şehir:</b>
              <span className="cagri-city">{cagri.city}</span>
            </div>

            <div
              dangerouslySetInnerHTML={{
                __html:
                  cagri.message.length > 250
                    ? cagri.message.slice(0, 250) + "..."
                    : cagri.message,
              }}
            />

            <button className="cagri-button" rel="noopener noreferrer">
              <a
                href={`https://www.google.com/maps/dir/?api=1&destination=${cagri.lat},${cagri.lng}`}
                target="_blank"
              >
                Yol Tarifi Al
              </a>
            </button>

            <Link to={`/cagri/${cagri.emergency_call_id}`}>
              <button
                className="cagri-button"
                variant="contained"
                color="primary"
              >
                Detaylar
              </button>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
}
