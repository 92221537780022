import "./CagriDetaylari.scss";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { getAuth } from "firebase/auth";
// import { useSelector } from "react-redux";
import Spinner from "../media/spinner.gif";
import { FiCopy } from "react-icons/fi";
import { Col, Row } from "react-bootstrap";
import YorumYap from "./YorumYap";
import { useNavigate, useLocation } from "react-router-dom";
import { BiArrowBack } from "react-icons/bi";
import Cookies from "universal-cookie";

const cagriDurumlari = {
  0: "Yardım Bekleniyor",
  1: "Yardım Yolda",
  2: "Yardım Edildi",
};

const CagriDetaylari = () => {
  // const user = useSelector((state) => state.user.value);
  let location = useLocation();
  const auth = getAuth();
  let { cagri_id } = useParams();
  const [cagri, setCagri] = useState();
  const [loading, setLoading] = useState(false);
  const [copied, setCopied] = useState(false);
  const navigate = useNavigate();

  const cookies = new Cookies();
  const locationList = cookies.get("locationList") || [];

  const updateCookies = () => {
    const newLocation = [...locationList];
    // Check if the last item is the same as the new item
    if (newLocation.length > 0 && newLocation[0].path === location.pathname) {
      // console.log("Same path, not adding to cookies");
    } else {
      if (cagri && cagri.city && cagri.message) {
        newLocation.unshift({
          path: location.pathname,
          user_id: auth.currentUser.uid,
          city: cagri.city,
          message: cagri.message,
        });
      }
      // Add the new path and user_id to the beginning of the array
    }

    // Keep the array size at a maximum of 10
    if (newLocation.length > 10) {
      newLocation.pop();
    }

    cookies.set("locationList", newLocation, { path: "/" });
  };

  const getCagriById = () => {
    axios
      .get(
        `https://rn3ovzqjig.execute-api.eu-central-1.amazonaws.com/api/emergency/${cagri_id}`
      )
      .then((res) => {
        setCagri(res.data[0]);

        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (auth && auth.currentUser && auth.currentUser.uid && cagri)
      updateCookies();
    // eslint-disable-next-line
  }, [auth, cagri]);

  const handleCopy = () => {
    try {
      setCopied(true);
    } catch (err) {
      // console.error("Failed to copy text: ", err);
    }
  };

  //   if copied is true, change it to false after 3 seconds
  useEffect(() => {
    if (copied) {
      setTimeout(() => {
        setCopied(false);
      }, 3000);
    }
  }, [copied]);

  useEffect(() => {
    if (cagri_id !== undefined && cagri_id !== null && cagri_id !== "") {
      setLoading(true);
      getCagriById();
    }
    // eslint-disable-next-line
  }, [cagri_id]);

  const epochtoTime = (epoch) => {
    const date = new Date(epoch * 1000);
    // if date is invalid, return "Tarih Bilinmiyor"
    if (isNaN(date.getTime())) {
      return "Tarih Bilinmiyor";
    }

    const options = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: false,
    };
    const formattedDate = date.toLocaleString("default", options);

    return formattedDate;
  };

  if (loading) {
    return (
      <div>
        <p>Çağrı Detayları Yükleniyor.</p>
        <img src={Spinner} alt="loading" />
        <p>
          Çağrı yüklenmezse hata formu doldurup çağrı kodunu bizimle paylaşın.{" "}
        </p>
        <p>
          Çağrı kodu: {cagri_id + "  "}
          {copied ? "Kopyalandı!" : <FiCopy onClick={handleCopy} />}
        </p>
      </div>
    );
  }
  return (
    <div>
      <Row>
        <Col xs={12} sm={6} style={{ padding: "30px" }}>
          <div className="cagri-harita-liste-tuslar">
            <div className="geri-tusu" onClick={() => navigate("/harita")}>
              <p>Çağrı Haritası</p>
            </div>
            <div
              className="geri-tusu"
              onClick={() => navigate("/cagrilistesi")}
            >
              <p>Çağrı Listesi</p>
            </div>
          </div>

          <h1>Çağrı Detayları</h1>
          {cagri && (
            <div className="cagri-detay">
              <p>
                <b>Oluşturulma Tarihi:</b> {epochtoTime(cagri.created_at)}
              </p>
              <p>
                <b>Son Güncellenme: </b> {epochtoTime(cagri.updated_at)}
              </p>
              <p>
                <b>Durum: </b>
                {cagriDurumlari[cagri.status]}
              </p>
              <p>
                <b>Kategori </b>
                {cagri.category}
              </p>
              <p>
                <b>Şehir: </b>
                {cagri.city}
              </p>
              <p>
                <b>Adres: </b>
                <div
                  dangerouslySetInnerHTML={{
                    __html: cagri.address,
                  }}
                />
              </p>
              <p>
                <b>Google Maps Konum Linki: </b>
                <a
                  className="konum-link"
                  target="_blank"
                  href={`https://www.google.com/maps/dir/?api=1&destination=${cagri.lat},${cagri.lng}`}
                >
                  Link{" "}
                </a>
              </p>
              <p>
                <b>Çağrı Detayları: </b>
                <br />
                <div
                  dangerouslySetInnerHTML={{
                    __html: cagri.message,
                  }}
                />
              </p>
            </div>
          )}
        </Col>
        <Col xs={12} sm={6}>
          {cagri && <YorumYap cagri={cagri} />}
        </Col>
      </Row>
    </div>
  );
};
export default CagriDetaylari;
