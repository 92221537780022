import React, { useState, useEffect } from "react";
import "./Home.scss";
import "../auth/Login.scss";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
// import Buttons from "../components/Buttons";
import { TbMap2 } from "react-icons/tb";
import { AiOutlineUnorderedList } from "react-icons/ai";
import { BsPinMap } from "react-icons/bs";
import { BiDonateHeart } from "react-icons/bi";
import Table from "react-bootstrap/Table";
import Alert from "@mui/material/Alert";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";

const introductionText = (
  <>
    <p>
      6 Şubat'ta olan Pazarcık ve Elbistan depremleri, 10 şehir ve yaklaşık 14
      milyon insanı direkt, kalan tüm Türk halkını ise dolaylı yoldan etkiledi.
    </p>
    <p>
      Depremzedelerin ihtiyaçlarının giderilmesinin kolaylaştırılması adına bu
      web sitesini geliştirdim.
    </p>
    <p>
      Uygulamayı geliştirirken önceliğim yüksek trafiği kaldırabilecek bir
      yapıda ve kolay bir kullanıma sahip olması oldu. Yardım çağrısı oluşturmak
      isteyen kişiler, hızlı bir şekilde haritada konumlarını seçerek yardım
      çağrısı oluşturabilirler. Yardım çağrılarına göre destek vermek isteyenler
      ise kolay bir şekilde harita üzerinde istedikleri bölgedeki çağrıları
      filtreleyerek ihtiyaçları takip edebilirler.
    </p>
    <p>
      Uygulamayı tamamen sıfırdan kod yazarak geliştirdim ve ihtiyaç halinde
      gereken tüm değişiklikleri yapabilirim. Uygulamayı kullanırken, uygulamada
      eksiklikler olabileceğini ve geliştirilmesi gereken yerlerin olduğunu
      unutmayınız. Farkettiğiniz hataları veya önerilerinizi lütfen iletişim
      sayfasından bana ulaştırınız.
    </p>
  </>
);

const Home = () => {
  const navigate = useNavigate();
  const [showInfoAlert, setShowInfoAlert] = useState(false);
  const cookies = new Cookies();
  const infoCookie = cookies.get("info");
  const visitedCagriList = cookies.get("locationList");

  useEffect(() => {
    if (!infoCookie) {
      setShowInfoAlert(true);
    }
  }, [infoCookie]);

  const closeInfoAlert = () => {
    cookies.set("info", Math.floor(Date.now() / 1000), {
      path: "/",
    });
    setShowInfoAlert(false);
  };

  const VisitedCagrilar = () => {
    return (
      <div style={{ marginTop: "50px" }}>
        <h5>En Son Ziyaret Edilen Çağrılar</h5>
        {visitedCagriList.length > 0 && (
          <Table
            striped
            bordered
            hover
            variant="dark"
            style={{ marginTop: "2rem" }}
          >
            <thead>
              <tr>
                <th>Şehir</th>
                <th>Mesaj</th>
                <th>Aksiyon</th>
              </tr>
            </thead>
            <tbody>
              {visitedCagriList.map((cagri, index) => (
                <tr key={index}>
                  <td>{cagri.city}</td>
                  <td>
                    <div
                      dangerouslySetInnerHTML={{
                        __html:
                          cagri.message.length > 50
                            ? cagri.message.slice(0, 50) + "..."
                            : cagri.message,
                      }}
                    />
                  </td>
                  <td>
                    <Button
                      className="card-button"
                      onClick={() => {
                        navigate(cagri.path);
                      }}
                    >
                      Detay
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
      </div>
    );
  };

  return (
    <div className="ana-sayfa">
      <div className="ana-sayfa-tuslar">
        <Link to={`/cagriformu`}>
          <Button variant="">
            <BsPinMap
              className="main-button-icon"
              color="white"
              style={{ marginRight: "10px" }}
            />
            Yardım Çağrısı Oluştur
          </Button>
        </Link>
        <Link to={`/harita`}>
          <Button variant="">
            <TbMap2 color="white" style={{ marginRight: "10px" }} /> Çağrı
            Haritası
          </Button>
        </Link>
        <Link to={`/cagrilistesi`}>
          <Button variant="">
            <AiOutlineUnorderedList
              color="white"
              style={{ marginRight: "10px" }}
            />
            Çağrı Listesi
          </Button>
        </Link>
        <Link to={`/bagis`}>
          <Button variant="">
            <BiDonateHeart color="white" style={{ marginRight: "10px" }} />
            Bağış Yapmak İstiyorum
          </Button>
        </Link>
        {/* Link to How to video on youtube https://www.youtube.com/watch?v=35hUovwSLAI */}
        <a href="https://www.youtube.com/watch?v=35hUovwSLAI" target="_blank">
          <Button>Kullanım Rehberi</Button>
        </a>

        {showInfoAlert && (
          <Alert severity="info" onClose={() => closeInfoAlert()}>
            {introductionText}
          </Alert>
        )}
        {visitedCagriList && visitedCagriList.length > 0 && <VisitedCagrilar />}
        <br />
        <div className="iletisim-notu" style={{ bottom: 0 }}>
          <p className="left">
            Hata bildirimi veya öneri için lütfen iletişim sayfasından bana
            ulaşınız.
          </p>
          <Link
            className="right"
            to={`/iletisim`}
            style={{ fontWeight: "bold", color: "blue" }}
          >
            İletişim Sayfası
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Home;
